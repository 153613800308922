<script setup lang="ts">
useHead({
  title: 'صفحه اصلی',
  link: [getCanonicalLink()],
})

useSeoMeta(getSeoMeta('صفحه اصلی'))
</script>

<template lang="pug">
NuxtLayout(name="main")
  NuxtPage
</template>
